<template>
  <div class="tournaments__list">
    <event-group-tournament-card :index="index" v-for="(tournament, index) in GET_EVENT_GROUPS_TOURNAMENTS" :tournament="tournament" :key="tournament.id"/>
    <div class="filled__grey" v-if="!allDataIsLoaded" @click="this.SET_MORE_EVENT_GROUPS_TOURNAMENTS">Показать еще</div>
  </div>
</template>

<script>
import TournamentCard from "@/components/tournaments/tournamentCard";
import {mapActions, mapGetters} from "vuex";
import EventGroupTournamentCard from "@/components/events-group/eventGroupTournamentCard";

export default {
  name: "tournamentsList-eventGroups",
  components: {EventGroupTournamentCard},
  computed: {
    ...mapGetters([
      'GET_EVENT_GROUPS_TOURNAMENTS',
      'GET_IS_ALL_DATA_LOADED',
    ]),

    allDataIsLoaded() {
      return this.GET_IS_ALL_DATA_LOADED
    }
  },

  methods: {
    ...mapActions([
        'SET_MORE_EVENT_GROUPS_TOURNAMENTS'
    ])
  },

  mounted() {
  },

  destroyed() {
  }
}
</script>

<style lang="scss" scoped>
.tournaments__list {
  .filled__grey {
    border-radius: 4px;
    max-width: 272px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 32px;
  }
}
</style>