<template>
  <section class="content-section content-section--p100 content-section--gradient" v-if="RATINGS_LIST.length">
    <div class="container">
      <h2 class="section-title">Рейтинг игроков <span
          class="section-title__affix">{{ GROUPS_OF_TOURNAMENTS[ratingsData.groupId - 1].game_name }}</span>
      </h2>
      <div class="ratings-grid">
        <rating-card :index="index" v-for="(participant, index) in RATINGS_LIST" :key="participant.id"
                     :participant="participant" :my-id="loggedUserId"/>
      </div>
      <div class="filled__grey" v-if="!ratingsData.ratingsFullyLoaded" @click="getMoreEntries">Показать еще</div>
    </div>
  </section>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import RatingCard from "@/components/events-group/rating-card";

export default {
  name: "ratings",
  components: {RatingCard},
  data() {
    return {
      ratingsData: {
        ratingsFullyLoaded: false,
        groupId: 0,
        page_number: 1,
        page_size: 50,
      },
      loggedUserId: undefined,
    }
  },
  props: {
    pageInfo: Object
  },
  computed: {
    ...mapGetters([
      'GROUPS_OF_TOURNAMENTS',
      'RATINGS_LIST',
      'RATINGS_LIST_META',
      'USER'
    ]),
  },
  methods: {
    ...mapActions([
      'GET_RATINGS_TABLES_IN_GROUP',
    ]),
    async getMoreEntries() {
      this.ratingsData.page_number++
      await this.GET_RATINGS_TABLES_IN_GROUP(this.ratingsData)
      if (Number(this.RATINGS_LIST_META['current-page']) === Number(this.RATINGS_LIST_META['last-page'])) this.ratingsData.ratingsFullyLoaded = true
    },
    async initialLoad() {
      this.ratingsData.groupId = this.$router.currentRoute.params.id
      await this.GET_RATINGS_TABLES_IN_GROUP(this.ratingsData)
      this.loggedUserId = this.USER.id
      if (Number(this.RATINGS_LIST_META['current-page']) === Number(this.RATINGS_LIST_META['last-page'])) this.ratingsData.ratingsFullyLoaded = true
    }
  },
  mounted() {
    this.initialLoad()
  }
}
</script>

<style scoped lang="scss">
.filled__grey {
  max-width: 272px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
  margin-top: 24px;
}
</style>