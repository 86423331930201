<template>
  <section class="content-section">
    <div class="section-head">
      <h2 class="section-title">турниры</h2>
    </div>

    <div class="sort-row">
      <ul class="sort-buttons">
        <li @click="setFilterFuture" :class="{'filter_selected': isFilterByFuture}">Будущие</li>
        <li @click="setFilterCurrent" :class="{'filter_selected': isFilterByCurrent}">сейчас идут</li>
        <li @click="setFilterAll" :class="{'filter_selected': isFilterByAll}">Все турниры
          ({{ getAllCountOfEventGroupsTournaments }})
        </li>
      </ul>
      <div class="sorting-controls">
        <!--"ascending" класс svg для переключения состояния-->
        <svg :class="{'ascending': isSortByIncrease, '': isSortByDecrease}"
             class="sort-icon"
             @click="this.changeSortOrder"
             width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path d="M5 8H19" stroke="#B0B0B0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M8 12.5H16" stroke="#B0B0B0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M10 17H14" stroke="#B0B0B0" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <div class="sgl-multiselect">
          сортировать по:
          <multiselect
              v-model="selectedSortOption"
              :options="selectOptions"
              track-by="name"
              label="name"
              :searchable="false"
              :close-on-select="true"
              :show-labels="false"
              :preselect-first="true"
              :allow-empty="false"
              @select="setSelectedSort"
          >
            <template slot="singleLabel" slot-scope="{option}">
              {{ option.name }}
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4 6L8 10L12 6" stroke="#B0B0B0" stroke-linejoin="round"/>
              </svg>
            </template>
          </multiselect>
        </div>
      </div>
    </div>

    <div v-if="GET_IS_HAVE_GROUPS_TOURNAMENTS">
      <tournaments-list-event-groups/>
    </div>
    <tournaments-list-skeleton v-else/>

  </section>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';

import TournamentsListSkeleton from "@/views/sceleton/tournaments-list-skeleton";
import TournamentsListEventGroups from "@/components/tournaments/tournamentsList-eventGroups";
import Multiselect from 'vue-multiselect';


export default {
  name: "events-list",
  components: {TournamentsListEventGroups, TournamentsListSkeleton, Multiselect},
  data() {
    return {
      options: [{
        id: 0,
        name: 'Все'
      }],
      observer: null,
      selectedSortOption: '',
      selectOptions: [{
        name: 'дате начала',
        value: 'started_at'
      }]
    }
  },
  computed: {
    ...mapGetters([
      'GET_IS_HAVE_GROUPS_TOURNAMENTS',
      'GET_SELECTED_FILTER_FOR_EVENTS',
      'GET_COUNT_OF_ALL_TOURNAMENTS_IN_EVENT_GROUPS',
      'GET_TYPE_OF_SORT_FOR_GROUPS_TOURNAMENTS'
    ]),

    getAllCountOfEventGroupsTournaments() {
      return this.GET_COUNT_OF_ALL_TOURNAMENTS_IN_EVENT_GROUPS
    },

    isFilterByAll() {
      return this.GET_SELECTED_FILTER_FOR_EVENTS.name === 'all'
    },

    isFilterByCurrent() {
      return this.GET_SELECTED_FILTER_FOR_EVENTS.name === 'current'
    },

    isFilterByFuture() {
      return this.GET_SELECTED_FILTER_FOR_EVENTS.name === 'future'
    },

    isSortByIncrease() {
      return this.GET_TYPE_OF_SORT_FOR_GROUPS_TOURNAMENTS === '+'
    },

    isSortByDecrease() {
      return this.GET_TYPE_OF_SORT_FOR_GROUPS_TOURNAMENTS === '-'
    }
  },

  methods: {
    ...mapActions([
      'SET_EVENT_GROUPS_TOURNAMENTS',
      'SET_SELECTED_FILTER_FOR_GROUPS_TOURNAMENTS',
      'SET_SELECTED_SORT_FOR_GROUPS_TOURNAMENTS',
      'TOGGLE_TYPE_OF_SORT_FOR_GROUPS_TOURNAMENTS'
    ]),

    async changeSortOrder() {
      this.TOGGLE_TYPE_OF_SORT_FOR_GROUPS_TOURNAMENTS();
      await this.SET_EVENT_GROUPS_TOURNAMENTS(this.$route.params.id);
    },

    async setFilterFuture() {
      this.SET_SELECTED_FILTER_FOR_GROUPS_TOURNAMENTS({name: 'future', value: '0'});
      await this.SET_EVENT_GROUPS_TOURNAMENTS(this.$route.params.id);
    },

    async setFilterCurrent() {
      this.SET_SELECTED_FILTER_FOR_GROUPS_TOURNAMENTS({name: 'current', value: '4'});
      await this.SET_EVENT_GROUPS_TOURNAMENTS(this.$route.params.id);
    },

    async setFilterAll() {
      this.SET_SELECTED_FILTER_FOR_GROUPS_TOURNAMENTS({name: 'all', value: null});
      await this.SET_EVENT_GROUPS_TOURNAMENTS(this.$route.params.id);
    },

    setSelectedSort(selectedOption) {
      this.SET_SELECTED_SORT_FOR_GROUPS_TOURNAMENTS(selectedOption.value)
    }
  },

  async mounted() {
    const eventId = this.$route.params.id;
    await this.SET_EVENT_GROUPS_TOURNAMENTS(eventId)
  }

}
</script>

<style scoped lang="scss">
.filled__grey {
  max-width: 272px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 8px;
  margin-top: 32px;
}
</style>