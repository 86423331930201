<template>
  <div class="event-banner" v-if="pageInfo.isShow">
    <img class="event-banner--bg" :src="pageInfo.currentGroup.background" alt="#">
    <div class="event-banner--content">
      <div class="event-banner--title">{{ pageInfo.currentGroup.attributes.name }}</div>
      <img class="event-banner--discipline" :src="pageInfo.currentGroup.game_logo" alt="#">
    </div>
  </div>
</template>
<script>

export default {
  name: "event-banner",
  props: {
    pageInfo: Object
  }
}
</script>

<style scoped>

</style>