<template>
  <router-link
      :to="{name: participant.type === 'users' ? 'another-profile' : 'team.name', params: {id: participant.id}}"
      target="_blank"
      :class="[{self: participant.id === myId},'sgl-player-panel']">
    <div class="place">{{ index + 1 }}.</div>
    <img class="avatar" :src="participant.attributes.logo" alt="#">
    <div class="info">
      <div class="name" v-if="participant.type === 'users'">{{ participant.attributes.login }}</div>
      <div class="name" v-if="participant.type === 'teams'">{{ participant.attributes.name }}</div>
      <div class="nickname">{{ participant.attributes.firstName }} {{ participant.attributes.lastName }}</div>
    </div>
    <div class="exp">{{ participant.rating }} exp</div>
  </router-link>
</template>

<script>
export default {
  name: "rating-card",
  props: {
    participant: Object,
    myId: undefined,
    index: {
      type: Number,
      default: 0
    },
  },
  computed: {},
}
</script>

<style scoped>

</style>