<template>
  <section class="content-section" v-if="isShowed">
    <div class="section-head">
      <h2 class="section-title">Оnline трансляция</h2>
    </div>
    <iframe class="sgl-embed-iframe" :src="getTwitchStreamSrc" allowfullscreen height="100%" width="100%"></iframe>
  </section>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "video-section",
  data() {
    return {
      stream: '',
      channel: '',
      isShowed: true
    }
  },
  props: {
    pageInfo: Object
  },
  computed: {
    getTwitchStreamSrc() {
      return `https://player.twitch.tv/?channel=${this.channel}&parent=${window.location.hostname}`
    }
  },
  methods: {
    ...mapActions([
      'GET_ACTIVE_STREAM'
    ]),
    async getStream() {
      this.isShowed = false;
      const res = await this.GET_ACTIVE_STREAM(this.pageInfo.currentGroup.sponsor.id)
      const attributes = res.data.data.attributes;

      if (attributes === null) return

      this.stream = attributes.url
      this.channel = new URL(this.stream).pathname.slice(1)

      this.isShowed = true
    }
  },
  mounted() {
    this.getStream()
  }
}
</script>

<style scoped>

</style>