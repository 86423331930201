<template>
  <div class="events-group">
    <div class="container">
      <event-banner :page-info="pageInfo"/>
      <video-section :page-info="pageInfo" v-if="pageInfo.isShow"/>
      <events-list :page-info="pageInfo" v-if="pageInfo.isShow"/>
    </div>
    <ratings :page-info="pageInfo" v-if="pageInfo.isShow"/>
  </div>
</template>

<script>
import Ratings from "@/components/events-group/ratings";
import EventBanner from "@/components/events-group/event-banner";
import VideoSection from "@/components/events-group/video-section";
import EventsList from "@/components/events-group/events-list";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "eventGroups",
  components: {EventsList, VideoSection, EventBanner, Ratings},
  data() {
    return {
      pageInfo: {
        isShow: false,
        currentGroup: {}
      }
    }
  },
  computed: {
    ...mapGetters([
      'GROUPS_OF_TOURNAMENTS',
      'GAMES'
    ]),
  },
  methods: {
    ...mapActions([
      'GET_GROUPS_OF_TOURNAMENTS',
    ]),
    async getGroupFromApi() {
      await this.GET_GROUPS_OF_TOURNAMENTS()
      await this.setCurrentGroup()
      this.pageInfo.isShow = true
    },
    setCurrentGroup() {
      this.pageInfo.currentGroup = this.GROUPS_OF_TOURNAMENTS.find(el => Number(el.id) === Number(this.$router.currentRoute.params.id))
    },
  },

  mounted() {
    if (!this.GROUPS_OF_TOURNAMENTS.length) {
      this.getGroupFromApi()
    } else {
      this.setCurrentGroup()
      this.pageInfo.isShow = true
    }
  }
}
</script>

<style scoped>

</style>